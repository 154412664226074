.info-container {
  padding: 2.5rem 3%;
  background: #f5f7f9;
}

.app-info {
  flex: 1;
}

.app-detail-title {
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.125rem;
  color: #212134;
  margin-top: 2rem;
  align-items: center;
}

.app-desc {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.01em;
  color: #32324d;
  margin-top: 1.5rem;
  max-width: 80%;
}

.app-video {
  width: 60%;
  flex: 1;
}

.app-youtube {
  width: 90% !important;
  height: auto !important;
  margin: auto !important;
  overflow: hidden;
  border-radius: 4px;
}

.review-counts {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration-line: underline;
  color: #4945ff;
  margin-left: 0.75rem;
}

/* .app-video article {
  height: inherit;
  width: inherit;
} */

.app-gallery {
  background-color: #ffffff;
  padding: 1rem 3%;
}
.sarovar-report {
  background-color: #ffffff;
  padding: 1rem 3%;
}
.gallery-heading {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 1.5rem;
}
.report-heading {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 1.5rem;
}
.gallery-image {
  flex-basis: 30%;
  border-radius: 0.75rem;
  overflow: hidden;
}

.gallery-image .ant-image {
  width: 100%;
}

.gallery-image img {
  height: 15rem;
  margin-right: 1.5rem;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}

.user-manual {
  font-size: 1rem !important;
  display: flex;
  align-items: center;
}

.user-manual .anticon {
  color: #e78840 !important;
}

.user-manual a {
  color: #e78840 !important;
}

.app-faqs {
  /* padding: 12px; */
  margin: 1.5rem 0;
  padding: 0 3%;
  background: #f5f7f9;
}

.app-faqs .ant-collapse-borderless {
  background: #f5f7f9 !important;
}

.ant-collapse-borderles {
  background: #f5f7f9;
}

.ant-collapse-header-text {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #212134;
}

.ant-collapse-content-box {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.01em;
  color: #32324d;
}

.ant-collapse-header {
  flex-direction: row-reverse;
  padding: 1rem !important;
}

.ant-collapse-expand-icon .anticon {
  font-size: 1rem !important;
  color: #98a2b3;
}

.gallery-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  row-gap: 2rem;
  /* justify-content: space-between; */
}
.report-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  row-gap: 2rem;
  /* justify-content: space-between; */
}
.profile-img {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  object-fit: contain;
}

.app-detail-buttons {
  margin-top: 1.875rem;
}

.reviews-section {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}
.sarovar-detail-buttons {
  margin-top: 1.875rem;
}
.refresh .label {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #000000;
}

.refresh .value {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #000000;
  margin-left: 4px;
}

.last-updated {
  margin-left: 1.5rem;
}

.refresh {
  margin-top: 1rem;
}
.sarovar-detail-buttons .sarovar-submit-btn {
  background-color: #009900;
  color: #ffffff;
  font-weight: 500;
}
.sarovar-detail-buttons .sarovar-cancel-btn {
  background-color: #ebf5df;
  color: #1e206a;
  font-weight: 500;
}
@media screen and (max-width: 650px) {
  .app-info {
    min-width: 20rem !important;
    width: -webkit-fill-available !important;
  }

  .app-video {
    min-width: 20rem !important;
    width: -webkit-fill-available !important;
  }

  .app-youtube {
    width: 100% !important;
    margin: auto !important;
    margin-top: 2rem !important;
  }

  .stream-player {
    width: 100% !important;
  }

  .app-desc {
    max-width: none !important;
  }

  .gallery-image {
    flex-basis: 40%;
  }

  .gallery-image img {
    height: 10rem;
  }
}

@media screen and (max-width: 850px) {
}
