.file-viewer {
  height: 100%;
  
  .react-pdf__Page__canvas {
    margin: 24px auto;
    width: 750px !important;
    height: 1100px !important;
    border: 1px solid;
  }

  .react-pdf__Document {
    height: calc(100% - 100px);
    overflow: auto;
  }

  .download-btn {
    width: 770px;
    margin: auto;
    text-align: right;
  }
}