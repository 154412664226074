@import '~bootstrap/dist/css/bootstrap.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 16px;
}

.ant-btn-sm {
  font-size: 12px !important;
}

.app-back-button {
  color: #4945ff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.ant-btn-primary {
  background: #4945ff !important;
  border-radius: 4px !important;
}

.ant-btn-secondary {
  background: #6360ff !important;
  border-radius: 4px !important;
  color: white !important;
}
.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.5) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
}
.ant-btn-cancel {
  background: #ffffff !important;
  border: 1px solid #dcdce4 !important;
  border-radius: 4px !important;
  color: #32324d !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.back-text {
  margin-left: 16px;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.text-right {
  text-align: right;
}

.cancel-btn {
  border: 1px solid #dcdce4 !important;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 4px !important;
  color: #1e206a !important;
  font-weight: 500 !important;
}

.cancel-btn:hover {
  background: #f0f0ff !important;
  /* border: 1.5px solid #4945ff !important; */
  border-color: #4945ff !important;
}

.submit-btn {
  background: #4945ff !important;
  color: #fff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  font-weight: 600 !important;
}

.ant-btn-link {
  color: #4945ff !important;
}

.app-buttons {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 4px !important;
}

.reject-btn {
  background: #ffe4e5 !important;
  border: 1px solid #d02b20 !important;
  color: #1e206a !important;
}

.accept-btn {
  color: #1e206a !important;
  background: #eafbe7 !important;

  border: 2px solid #1cb697 !important;
}

.cursor-pointer {
  cursor: pointer;
}

/* .add-app-form .ant */

/* ant form styles */

.ant-form-item-control .ant-input,
.ant-form-item-control .ant-select {
  /* border: 1px solid #cfd7df; */
  border: 1px solid #cfd7df;
  border-radius: 4px;
}

.ant-select-selector {
  border: none;
}

.ant-form-item-label {
  font-weight: 600;
  font-size: 14px;

  color: #32324d;
}

.ant-table-tbody > tr > td {
  padding: 6px 8px !important;
}

.header-buttons {
  position: absolute;
  top: 0;
  right: 0;
}

.search-bar {
  padding: 3px 10px !important;
  background-color: white;
  width: 60% !important;
  border: 1px solid #dcdce4 !important;
  margin-right: auto;
  flex: 1;
}

.react-pdf__Page__annotations.annotationLayer,
.react-pdf__Page__textContent.textLayer {
  display: none !important;
}

/* REsponsive */

@media screen and (max-width: 1000px) {
  .app-header .app-search {
    display: none !important;
  }
}

@media screen and (max-width: 650px) {
  html {
    font-size: 12px;
  }

  a.ant-btn {
    line-height: 1.625rem !important;
  }

  .ant-btn {
    height: 2rem !important;
    font-size: 0.875rem !important;
  }

  .ant-avatar {
    width: 1.25rem !important;
    height: 1.25rem !important;
    line-height: 1.25rem !important;
    font-size: 0.875rem !important;
  }

  .ant-radio-button-wrapper {
    height: 2rem !important;
    padding: 0 0.5rem !important;
    line-height: 1.25rem !important;
    font-size: 0.875rem !important;
  }

  .anticon {
    font-size: 0.875rem !important;
  }

  .welcome-container .welcome-banner-title {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }

  .welcome-container .left,
  .welcome-container .right {
    width: -webkit-fill-available !important;
    margin-top: 1rem;
  }

  .app-header .app-logo {
    margin-left: 1rem;
    height: 1.5rem;
  }

  .app-header .app-search {
    display: none !important;
  }

  .homepage-container .slick-slide {
    padding: 1rem 2rem !important;
  }

  .home-sidenav {
    display: none !important;
  }

  .home-search {
    font-size: 1rem !important;
  }

  .side-nav-trigger {
    display: flex !important;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
  }

  .home-sidenav.show {
    display: inline !important;
    position: absolute !important;
    width: 50% !important;
    z-index: 1 !important;
    top: 2rem;
  }

  .home-apps .card-item {
    flex-basis: 75% !important;
    margin: auto;
    max-width: none !important;
    width: -webkit-fill-available !important;
  }
}

@media screen and (min-width: 650px) and (max-width: 920px) {
  html {
    font-size: 12px;
  }

  .welcome-container .welcome-banner-title {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }

  .welcome-container .left,
  .welcome-container .right {
    width: -webkit-fill-available !important;
    max-width: 60%;
    margin: auto;
    margin-top: 1rem;
  }

  .home-apps .card-item {
    flex-basis: 42% !important;
    margin: auto;
    max-width: none !important;
    /* width: -webkit-fill-available !important; */
  }
}
