.stream-player {
  position: relative;
  width: 90%;
  overflow: hidden;
  padding-top: 48.25%; /* 16:9 Aspect Ratio */

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}