.app-header {
  min-height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 5px rgba(39, 49, 58, 0.1);
  position: relative;
  z-index: 2;
  flex-wrap: wrap;
  overflow-y: visible;
  padding: 4px;
  /* filter: drop-shadow(0px 2px 10px rgba(44, 92, 197, 0.2)); */
}

.app-logo {
  height: 2.5rem;
  margin-left: 2rem;
}

.header-right {
  margin-right: 1.25rem;
  display: flex;
}

.user-dropdown {
  display: flex;
  align-items: center;
  background: #fafafa;

  border: 1px solid #cfd7df;
  border-radius: 4px;

  padding: 4px;
  margin-left: 0.5rem;
}

.app-search {
  background-color: #f0f1f2 !important;
  border-radius: 4px !important;
  border: none !important;
  width: 20rem !important;
  font-size: 1rem !important;
}

.app-search .ant-input {
  background-color: #f0f1f2;
}

.app-search ::placeholder {
  color: #8e8ea9 !important;
  opacity: 1; /* Firefox */
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #4945ff !important;
  border-color: #4945ff !important;
}
