.form-header {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  margin: auto;
  margin-bottom: 30px;
  color: #212134;
  position: relative;
}

.add-app-container {
  padding: 2% 5%;
  background: #f5f7f9;
}

.manage-users-container {
  padding: 2% 5%;
  background: #f5f7f9;
  height: 100%;
}

.manage-users-card {
  width: 85%;
  margin: auto !important;
  margin-top: 24px !important;
}

.manage-users-card .ant-card-body {
  padding-top: 8px !important;
}

.add-app-container .ant-input {
  padding: 8px 16px !important;
  border: 1px solid #cfd7df !important;
  border-radius: 4px !important;
}

.add-app-form .ant-form-item-row {
  width: 60%;
  margin: auto;
}

.manage-users-tab {
  width: 100%;
}

/* .requests-container {
} */

.manage-users-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4945ff !important;
}

.manage-users-tab .ant-tabs-tab-active {
  border-color: #4945ff !important;
}

.manage-users-tab .ant-tabs-tab-btn {
  display: flex;
  align-items: center;
}

.manage-users-tab .ant-tabs-ink-bar {
  background: #4945ff !important;
}

.manage-button {
  width: 60%;
  margin: auto;
  text-align: right;
}
.AD_title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.view-btn-style {
  color: #4945ff;
  cursor: pointer;
}
.view-btn-style:hover {
  color: #0805a5;
  cursor: pointer;
}
.report-input > div {
  margin: auto;
  width: 100% !important;
}
.report-textArea {
  height: 42px !important;
}
.col-padding-reports {
  padding-left: 0px !important;
  /*  padding-right: 0px !important; */
}
.report-delete {
  color: #a3a3a4;
}
.report-delete:hover {
  color: #d40e00;
}
