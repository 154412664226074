.request-access-container {
  padding: 2% 8%;
}

.request-access-header {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 30px;
  color: #212134;
}

.request-access-form .ant-form-item-row {
  /* width: 60%; */
  margin: auto;
}

.upload-area {
  width: 60%;
  margin: auto;
  margin-bottom: 24px;
}

.requester-info {
  /* width: 70%; */
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 16px;
}

.requester-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;

  color: #4a4a6a;
}

.requester-value {
  font-size: 14px;
  color: #212134;
  font-weight: 500;
  margin-left: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.requester-info .item {
  flex: 1;
  max-width: 30%;
  display: flex;
  align-items: center;
}
