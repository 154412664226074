.feedback-container {

  width: 100%;
  padding: 2% 3%;

  .feedback-appname {
    color: #2f2f33eb;
    font-weight: 600;
    font-size: 18px;
    margin-right: 8px;
  }

  .feedback-user {
    color: #35383cbf;
    font-weight: 500;
  }

  .feedback-title {
    color: #000000cf;
    font-weight: 600;
    font-size: 15px;
    margin: 12px 0 2px 0;
    line-height: 18px;
  }

  .feedback-description {
    font-size: 15px;
    line-height: 16px;
  }
}