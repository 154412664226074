.welcome-container {
  height: 100%;

  .ant-carousel {
    width: 100%;
    height: 100%;
  }

  .welcome-carousel {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .welcome-carousel .slick-slide[data-index='0'] {
    background: url('../../assets/banner-bg.jpeg');
  }

  .welcome-carousel .slick-slide[data-index='1'] {
    background: url('../../assets/h-250-banner.svg');
  }

  .welcome-carousel {
    .slick-list {
      height: 100%;
    }

    .slick-track {
      height: 100%;
    }

    .slick-slide {
      display: flex !important;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
    }
  }

  .welcome-banner {
    // background-image: url("../../assets/banner-bg.jpeg");
    height: 18.75rem;
    background-size: cover;
    background-position: 0 700px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .welcome-stats {
    background-image: url('../../assets/stats-bg.jpeg');
    min-height: 18.75rem;
    height: auto;
    background-size: cover;
    // background-position: 0 870px;
    padding: 5% 10%; //----------
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .welcome-banner-title {
    font-weight: 600;
    font-size: 2.625rem;
    line-height: 3rem;
    text-align: center;
    letter-spacing: -0.02em;

    color: #ffffff;
  }

  .welcome-banner-desc {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.625rem;
    text-align: center;

    color: #ffffff;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      align-items: center;
      padding: 0.625rem 1.75rem;
      border-radius: 8px;
      display: flex;
      font-weight: 500;
      font-size: 1rem;
      height: 3rem;

      img {
        width: 1.25rem;
        margin-right: 0.5rem;
      }

      &:hover {
        transform: scale(1.05);
        // box-shadow: 2px 2px 2px rgb(60, 59, 59);
      }
    }
    .bg-white {
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      color: #344054;
    }

    .bg-highlighted {
      background: #1c4e9d;
      border: 1px solid #1c4e9d;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      color: white !important;
    }
    .bg-sarovar {
      background: green;
      border: 1px solid green;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      color: white !important;
    }
  }

  .left {
    width: 62%; //---------
    min-width: 20rem;

    .updates-card {
      border: 1px solid #eaecf0;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 16px;
      overflow: hidden;

      .ant-card-body {
        padding: 0 !important;
      }

      .updates-card-header {
        padding: 0.75rem 1.25rem;
        background-color: #1c4e9d;
        .heading {
          font-weight: 700;
          font-size: 1rem;
          line-height: 1.5rem;
          color: #fff;
          display: flex;
          justify-content: space-between;
        }

        .desc {
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: #fff;
        }
      }

      .updates-container {
        max-height: 400px;
        overflow-y: auto;
      }

      .updates-item {
        border-top: 1px solid #eaecf0;
        padding: 0.75rem 1.25rem;
        column-gap: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.25rem;
          color: #101828;
        }

        .desc {
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1.25rem;
          color: #667085;
        }

        .date {
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1.25rem;
          color: #667085;
        }

        .arrow-bg {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          background: aliceblue;
          justify-content: center;
          text-align: center;
          align-items: center;
          padding-bottom: 0.675rem;
        }
      }
    }
  }

  .right {
    width: 30%;
    // margin-left: auto;
    min-width: 20rem;

    .counts-card {
      border: 1px solid #eaecf0;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 16px;

      margin-bottom: 1.25rem;
      .ant-card-body {
        padding: 0.5rem 0;
      }

      .counts-item {
        display: flex;
        border-bottom: 1px solid #eaecf0;
        padding: 0.5rem 1rem;

        &:last-child {
          border: none !important;
        }

        .counts-label {
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: #101828;
          margin-left: 8px;
        }

        img {
          width: 30px;
        }
      }
    }

    .upcoming-card {
      border: 1px solid #eaecf0;
      /* Shadow/lg */

      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 16px !important;

      .ant-card-body {
        padding: 8px 0;
      }

      .upcoming-header {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #1c4e9d;
        padding: 0 1rem 0.5rem 1rem;
      }

      .upcoming-container {
        max-height: 270px;
        overflow-y: auto;
      }

      .upcoming-item {
        display: flex;
        border-top: 1px solid #eaecf0;
        padding: 0.5rem 1rem;

        .title {
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.5rem;
          color: #101828;
        }

        .desc {
          font-weight: 400;
          font-size: 0.9rem;
          line-height: 1.25rem;
          color: #667085;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          // height: 48px;
        }
      }

      .app-icon img {
        width: 3.5rem;
        height: 3.5rem;
      }
    }
  }

  // scroll-bar
  ::-webkit-scrollbar {
    width: 0.625rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.5rem;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.carousel-container > div {
  margin-left: -200px !important;
}
