.card-container {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(39, 49, 58, 0.1);
  border-radius: 8px;
  cursor: pointer;
  /* width: 300px;
  height: 350px; */
}
.card-container-app {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(39, 49, 58, 0.1);
  border-radius: 8px;
  cursor: pointer;
 /*  width: 300px; */
  /* height: 350px; */
}
.card-container-padding {
  padding: 22px 18px;
}
.app-card-title {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;

  color: #212134;
  max-width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.app-card-icon {
  color: #666666;
  margin-left: auto;
  font-size: 1rem;
}
.app-card-icon-direction {
  display: flex;
  flex-direction: column;
}
.card-ribbon {
  display: flex;
  justify-content: end;
}
.card-ribbon-text {
  background-color: rgb(249, 126, 82);
  font-size: 10px;
  color: white;
  padding: 0px 10px;
  border-radius: 2px;
}
.card-container .card-header {
  display: flex;
  align-items: center;
}
.card-container-app .card-header {
  display: flex;
  align-items: center;
}
.card-app-desc {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  /* or 150% */

  letter-spacing: 0.01em;

  /* Black/32324D */

  color: #32324d;
  margin-bottom: 12px;
  margin-top: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  height: 2.625rem;
}

.app-tag {
  padding: 2px 8px !important;
  border-radius: 12px !important;
  color: #4a4a6a !important;
}

.card-actions button {
  border-radius: 14px;
  padding: 0 8px;
  background: white;
  border: 2px solid #666687;
  color: #333333;
  font-weight: 600;
  font-size: 0.875rem;
}

.card-actions {
  justify-content: right;
  display: flex;
  align-items: center;
  border-top: 1px solid #eaeaef;
  margin-top: 1rem;
  padding-top: 12px;
}

.card-actions .middle-icon .ant-dropdown-trigger {
  margin: 0 12px;
  display: flex;
  align-items: center;
}

.card-actions .anticon {
  font-size: 1rem;
}

.app-profile-img {
  margin-right: 12px;
  width: 2.375rem;
  height: 2.375rem;
  align-items: center;
  display: flex;
}

.app-profile-img img {
  max-width: 100%;
}

.card-buttons {
  border: 2px solid #666687 !important;
  border-radius: 1rem !important;
  color: #333333 !important;
  margin-left: 8px;
}

.quick-look,
.middle-icon .ant-dropdown-trigger {
  color: #333333 !important;
}

.card-buttons:hover {
  background: #f0f0ff !important;
  border: 2px solid #4945ff !important;
}

.feature-image img {
  max-width: 90%;
  width: 90%;
  object-fit: cover;
}

.feature-image {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  height: 80px;
}

.upcoming-badge sup {
  background: rgb(255, 87, 34);
  height: 2rem;
  border-radius: 1.25rem;
  display: flex !important;
  align-items: center;
  padding: 0 0.75rem;
}
