.request-list-container {
  width: 100%;
  padding: 2% 3%;
}

.status-text {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #212134;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.request-card {
  background: #ffffff;
  border: 1px solid #ebeff3;
  /* Question card */

  box-shadow: 0px 2px 5px rgba(39, 49, 58, 0.1);
  border-radius: 5px;
  padding: 20px 32px;

  /* width: 80%; */
  /* margin: auto; */
  margin-bottom: 20px;
  position: relative;
}

.request-card .request-app-title {
  width: 40%;
}

.request-card .request-details {
  flex: 1;
}

.request-card .prop-label {
  width: 25%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #4a4a6a;
}

.request-card .prop-value {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-left: 18px;
  color: #212134;
}

.request-app-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  color: #212134;
  display: flex;
  height: fit-content;
  align-items: center;
}

.request-card .actions-row {
  border-top: 1px solid #ebeff3;
  margin-top: 12px;
}

.request-card .actions-row {
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
}

.reject-btn {
  background: #ffe4e5 !important;

  border: 1px solid #d02b20 !important;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 4px !important;
  color: #1e206a !important;
  font-weight: 500 !important;
}

.accept-btn {
  background: #eafbe7 !important;

  border: 2px solid #1cb697 !important;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 4px !important;
  color: #212134 !important;
  font-weight: 600 !important;
  margin-left: 12px;
}

.app-name {
  margin-left: 24px;
}

.documents-chip {
  background: #ebeff3;
  border-radius: 12px;
  display: flex;
  align-items: center;
  color: #4945ff;
  padding: 3px 16px;
  font-size: 14px;
  cursor: pointer;
}

.doc-name {
  margin-left: 4px;
  font-size: 12px;
}

.pagination {
  margin: 24px 0;
}

.check-icon {
  position: absolute;
  top: 0;
  left: 8px;
}
.ant-table-thead > tr > th {
  font-weight: bold;
  font-size: 16px;
}
.reason-header {
  font-size: 14px;
  font-weight: 500;
}
