.home-apps {
  display: flex;
  position: relative;
}

.home-sidenav {
  width: 25%;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #dcdce4;
  min-height: calc(100vh - 60px);
}

.homepage-container {
  background: #f5f5f5;
}

.apps-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  /* 2.5rem; */
  /* row-gap: 2.5rem; */
}

.card-item {
  flex-basis: 30%;
  /*  max-width: 30%; */
  /* overflow: hidden; */
}

.apps-with-access {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  /* Neutral/900 */

  color: #212134;
  margin-top: 1rem;
}

.apps-layout {
  padding: 0 3% 2%;
}

.requests-section {
  padding: 2% 3%;
  width: 100%;
}

.status-heading {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #212134;
  margin-bottom: 1.5rem;
}

.status-review-btn {
  background: #4945ff !important;
  border-radius: 4px !important;
  color: white !important;
  width: 120px;
}

.app-carousel {
  /* background-color: #403cec; */
  height: 240px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* padding-left: 10%; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-carousel .slick-list {
  height: 100%;
}

.app-carousel .slick-track {
  height: 100%;
}

.app-carousel .slick-slide {
  padding: 0 100px;
  display: flex !important;
  align-items: center !important;
}

.applications-carousel .slick-slide[data-index='0'] {
  background: url('../../assets/apps-bg.png');
}

.applications-carousel .slick-slide[data-index='1'] {
  /* background: url('../../assets/corp-apps.jpg'); */
  background: url('../../assets/h-250-banner.svg');
}

.dash-carousal .slick-slide[data-index='0'] {
  background: url('../../assets/dashboards-bg.png');
}
.sarovar-carousal .slick-slide[data-index='0'] {
  background: url('../../assets/sarovar-banner-bg.png');
  background-position: center;
  background-size: cover;
  width: 100% !important;
  background-repeat: no-repeat;
}
/* .welcome-to {
} */

.tab-icon {
  margin-right: 6px;
}

.carousel-title {
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 2.75rem;
  /* or 150% */

  color: #ffffff;
}

.carousel-highlight {
  font-weight: 700;
}

.carousel-desc {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #ffffff;
  margin-top: 12px;
}

.ant-carousel {
  height: 100%;
}

.ant-carousel .slick-slider {
  height: 100%;
}

.home-tabs .ant-tabs-nav {
  background: white;
}

.home-tabs .ant-tabs-nav-wrap {
  justify-content: center;
}

.home-tabs {
  width: 100%;
}

/* .requests-container {
} */

.home-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4945ff !important;
}

.home-tabs .ant-tabs-tab-active {
  border-color: #4945ff !important;
}

.home-tabs .ant-tabs-tab-btn {
  display: flex;
  align-items: center;
}

.home-tabs .ant-tabs-ink-bar {
  background: #4945ff !important;
}

.home-search {
  background-color: white;
  width: 60% !important;
  border: 1px solid #dcdce4 !important;
  margin-right: auto;
  flex: 1;
}

.home-buttons {
  border: 1.5px solid #212134 !important;
  height: 2.25rem !important;
  margin-left: 0.75rem;
  color: #212134 !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  display: flex !important;
  align-items: center !important;
  padding-bottom: 0 !important;
}

.home-buttons:hover {
  background: #f0f0ff !important;
  border: 1.5px solid #4945ff !important;
}

.home-actions-bar {
  display: flex;
  justify-content: end;
}

.modal-header {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #32324d;
}

.review-label {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #32324d;
}

.review-value {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: #666687;
  text-transform: capitalize;
}

.carousel-adjust {
  height: 100%;
  width: 100%;
  /* padding: ; */
  margin: auto;
  z-index: 9;
}

.dropdown-table {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #dcdce4;
  border-radius: 4px;
  margin-left: 4px;
}

.dropdown-table .anticon {
  color: #001a72;
  font-size: 12px;
}

.checkout-btn {
  margin-top: 24px;
}

.checkout-btn:hover {
  background: #f0f0ff !important;
  /* border: 1.5px solid #4945ff !important; */
  border-color: #4945ff !important;
  color: #1e206a !important;
}

.empty-text {
  font-size: 20px;
  color: rgb(0 0 0 / 50%);
  font-weight: 600;
}

.quicklook-modal .ant-modal-body {
  padding-top: 40px;
}

.side-nav-trigger {
  display: none;
  cursor: pointer;
}
.update-card-title {
  font-size: 20px;
  font-weight: 600;
}
.card-release_notes-title {
  font-size: 16px;
  font-weight: 600;
}
.card-release_notes {
  font-size: 14px;
  text-transform: inherit;
}
.update-card-posted_date {
  font-size: 14px;
}

.pending-view-capitalize {
  text-transform: capitalize;
}
