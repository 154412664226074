.app-menu-title {
  color: #32324dc9;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 14px;
  margin-bottom: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1px;
}

.app-menu-title.active {
  background: #4945ff;
  border-radius: 4px;
  color: white;
  font-weight: 500;
}

.category-header {
  color: #1e206a;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin: 36px 0 4px 10px;
}

.sidenav-container {
  /* background: #fff;
  padding: 16px; */
}

.cat-icon {
  margin-right: 0.75rem;
  font-size: 14px;
  display: flex;
}

.cat-icon .anticon {
  position: relative !important;
  top: 1px !important;
}
