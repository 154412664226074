.accessLevel_style {
  border: 1px solid #4945ff !important;
  border-radius: 4px;
  color: #4945ff !important;
}
.accessLevel_style:hover {
  border-color: black !important;
  color: black !important;
}
.template_bulkUpload {
  color: #4945ff !important;
  cursor: pointer;
  margin-top: 20px;
}
.template_bulkUpload:hover {
  color: black !important;
}
