.background-items {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.app-background {
.dots-t1 {
  left: 200px;
  position: absolute;
  top: 50px;
}

.dots-t1 .dots {
  background: #2b27e6;
}

.dots-b1 .dots {
  background: #2b27e5;
}

.dots-b1 {
  position: absolute;
  bottom: 50px;
  right: 0;
  transform: rotateY(0deg) rotate(-45deg);
}

.square-l1 {
  background: #150dcb;
  height: 100px;
  width: 100px;
  position: absolute;
  top: 25px;
  translate: rotate(45deg);
  left: -50px;
  transform: rotateY(0deg) rotate(45deg);
}

.square-l2 {
  background: #150dcb;
  height: 100px;
  width: 100px;
  position: absolute;
  top: 25px;
  translate: rotate(45deg);
  left: 12px;
  opacity: 0.4;
  transform: rotateY(0deg) rotate(45deg);
}

.square-t1 {
  background: #150dcb;
  height: 100px;
  width: 100px;
  position: absolute;
  top: -50px;
  translate: rotate(45deg);
  left: 58%;
  opacity: 0.4;
  transform: rotateY(0deg) rotate(45deg);
}

.square-t2 {
  background: #150dcb;
  height: 100px;
  width: 100px;
  position: absolute;
  top: -50px;
  translate: rotate(45deg);
  left: 60%;
  opacity: 0.5;
  transform: rotateY(0deg) rotate(45deg);
}

.square-b1 { 
  background: #150dcb;
  height: 100px;
  width: 100px;
  position: absolute;
  bottom: 0px;
  translate: rotate(45deg);
  left: 40%;
  opacity: 0.4;
  transform: rotateY(0deg) rotate(45deg);
}

.square-b2 {
  background: #150dcb;
  height: 100px;
  width: 100px;
  position: absolute;
  bottom: -50px;
  translate: rotate(45deg);
  left: 40%;
  opacity: 0.8;
  transform: rotateY(0deg) rotate(45deg);
}

.circle-b1 {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  position: absolute;
  bottom: -75px;
  left: -75px;
  background: #2e27e6;
}

.circle-r1 {
  position: absolute;
  background: #2b24e4;
  opacity: 0.5;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  bottom: 100px;
  right: 170px;
}

.circle-r2 {
  position: absolute;
  background: #2b24e4;
  opacity: 0.8;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  bottom: 100px;
  right: 140px;
}
}

.dashboard-background {
  overflow: hidden;
  .circle-b1 {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    position: absolute;
    bottom: -75px;
    left: -75px;
    background: rgba(164, 217, 246, 0.3);
  }
  
  .circle-r1 {
    position: absolute;
    background: rgba(164, 217, 246, 0.1);
    // opacity: 0.5;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    top: 20px;
    right: calc(40% + 30px);
  }
  
  .circle-r2 {
    position: absolute;
    background: rgba(164, 217, 246, 0.2);
    // opacity: 0.8;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 60px;
    right: 40%;
  }

  .dots-t1 .dots {
    background: rgba(164, 217, 246, 0.2);
  }

  .bars-b {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotateY(0deg) rotate(180deg);
    margin-right: 20px;

    .bars {
      width: 20px;
      margin-right: 16px;
      background: rgba(164, 217, 246, 0.2);
    }
  }
}