.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background-shapes {
  position: fixed;
  top: 0;
}

.left.half-circle {
  position: fixed;
  top: 10%;
  left: -40px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(43, 36, 228, 0.1);
}

.left.square1 {
  background: #d9d8ff;
  opacity: 0.4;
  height: 100px;
  width: 100px;
  transform: rotate(-45deg);
  position: fixed;
  left: -50px;
  top: 40%;
}

.left.square2 {
  background: #d9d8ff;
  opacity: 0.2;
  height: 100px;
  width: 100px;
  transform: rotate(-45deg);
  position: fixed;
  left: 20px;
  top: 40%;
}

.dots {
  width: 8px;
  height: 8px;
  background: #d9d8ff;
  border-radius: 50%;
}

.left.dots-container {
  position: fixed;
  bottom: 10%;
  left: 10%;
}

.right.circle1 {
  position: fixed;
  top: 10%;
  right: -10px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(43, 36, 228, 0.1);
}

.right.dots-container {
  position: fixed;
  top: 40%;
  right: 32px;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-content {
  /* height: calc(100vh - 60px); */
  overflow-y: auto;
}
