.login-container {
  display: flex;
  padding: 2rem 5%;
  justify-content: space-between;
}

.left_screen {
  flex: 1;
  flex-basis: 40%;
}

.left_screen .illustration {
  height: 100%;
  width: 100%;
  img {
    max-width: 100%;
    object-fit: scale-down;
    height: 100%;
  }
}

.right_screen {
  flex: 1;
  flex-basis: 40%;
  background-color: #1c4e9d;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 0.75rem solid #2359af;
  padding: 0.5rem;
}

.welcome-text {
  font-weight: 700;
  font-size: 1.75rem;
  color: #ffffff;
  line-height: 1;
}

.desc-text {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #f0f0f0;
  margin: 12px 0;
}

.features {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.25rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
}

.features .anticon {
  margin-right: 0.5rem;
}
