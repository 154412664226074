.container {
  position: relative;
  padding: 0px !important;
  border-radius: 4px !important;
}
.report-card {
  /* width: 408px; */
  border-radius: 0.75rem;
  padding: 0px !important;
  border-radius: 4px !important;
}
.report-card .ant-card-head {
  background-color: #ebf5df; /* Set your desired background color */
  font-weight: 600;
  padding: 0px 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.report-card .ant-card-head-title {
  padding: 10px 0;
}
.report-card .ant-card-actions {
  display: grid;
  justify-content: flex-end;
}
.ant-card-actions > li {
  margin: 0px 0;
}
.ant-card-body {
  padding: 10px;
}

/* Optional: Style the button */
.report-card .ant-card-actions .view-report-btn {
  background-color: #009900;
  margin: 5px;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 600;
}
a.ant-btn-disabled,
a.ant-btn-disabled:hover,
a.ant-btn-disabled:focus,
a.ant-btn-disabled:active {
  background-color: #d9d9d9 !important;
  color: black !important;
}
@media screen and (max-width: 650px) {
  .report-card {
    width: 400px;
    margin-bottom: 2px;
  }
}
.card_description {
  display: -webkit-box;
  -webkit-line-clamp: 8; /* Limit to 6 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #646464;
}
